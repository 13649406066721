"use strict";

if (typeof isPreview != 'undefined' && isPreview) {

  // open raw xml mode in new tab on ctrl+alt+r
  (function open_raw_mode(){
    var url = (window.location + '').split('#')[0];
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var params = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = url.split('?')[0] + '/raw' + params;
        window.open(newUrl,'_blank');
      }
    });
  })();
  
}
else {

  // google analytics
  if (typeof gaCode != 'undefined' && gaCode) {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
    ga('create',gaCode,'auto');
    ga('send','pageview');
  }
  
}

$(document).ready(function () {
  var homeImages = $(".homeImages");
  if (homeImages.length < 1) return;
  var str = homeImages.attr('data-images').replace(/'/g, '"');
  
  var allImages = JSON.parse(str);
  var deskImages = allImages.desktop;
  var mobImages = allImages.mobile;
  var images = deskImages;
  var winSize = 735;
  initImages($(window).width());
  $(window).resize(function() {
    initImages($(window).width());
  });
  
  function initImages(curSize) {

    images = curSize <= winSize ? mobImages : deskImages;
    for (var i=0; i<images.length; i++){
      var img = $('#img-'+i);
      if (img.length < 1){
        homeImages.append('<div id="img-'+i+'" />');
        img = $('#img-'+i);
      }
      if (i==0){
        img.addClass('opaque');
      }
      img.css(
        "background-image", "url("+ images[i] + ")"
      );
    }
    homeImages.css(
      "height", curSize <= winSize ?  "225px" :  "375px" 
    );
  }
  
  function changeBackgroundImage() {
    if (images.length > 1){
      var active = homeImages.find('.opaque');
      var pnext = active.next();
      var next = pnext.length < 1 ? homeImages.find('#img-0') : pnext;
      
      active.toggleClass('opaque');
      next.toggleClass('opaque');
    }
 }
  setInterval(changeBackgroundImage, 6000);
});

// website exit
(function external_in_new_window(){
  var domain = document.location.protocol + '//' + document.location.hostname;
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });
})();

// build email links (spam prevention)
$('a.email').each(function(){
	var pre       = $(this).data('pre');
	var after     = $(this).data('after');
	var textPre   = $(this).data('text-pre');
	var textAfter = $(this).data('text-after');
	$(this).attr('href', 'mailto:' + pre + '@' + after);
	if (textPre) $(this).text(textPre + '@' + textAfter);
});

// left/right placement of tooltips 
if ($('.content')[0]) {
  var leftPosContent = $('.content').offset().left;
  var widthContent   = $('.content').width();
  $('.hint--bottom').each(function(){
    var leftPos = $(this).offset().left;
    if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
  });
}

// collapse and popup large tables
$('.table-zoom')
.each(function(){
  var minMargin   = 250;
  var zoombutton  = $(this);
  var table       = zoombutton.parent();
  var tableHeight = table.height();
  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;
  
  zoombutton.css('top',positionTop+'px');
  
  if (tableHeight > 600) {
     var tableTop = table.offset().top;
    $(window).scroll(function(){
      var tableScrollPos = $(this).scrollTop() - tableTop;
       if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
         positionTop = minMargin + tableScrollPos;
         zoombutton.css('top',positionTop+'px');
       }
     });
   }
})
.click(function(){
  $(this).parent().addClass('popup');
})
;

$('.table-close').click(function(){
  $(this).parent().removeClass('popup');
});

// fix left menu on page scroll
$(window).scroll(function(){
  var navHeight  = $('.sidebar-left').height();
  var pageHeight = $('.content').height();
  var maxMT      = pageHeight - navHeight - 80;
  if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
    var scrollPos = $(this).scrollTop();
    var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
    margintop     = margintop > maxMT ? maxMT : margintop;
    $('.sidebar-left').css('margin-top',margintop);
  }
});
$(function(){
  $('.banner:first-child').show();
  if ($('.banner').length > 1) setInterval('showNextBanner()',5000);
  $('.main-nav a').click(function(){
    $('.main-nav a').removeClass('active');
    $(this).addClass('active');
  });
});
function showNextBanner() {
  var current = $('.banner:visible');
  var next    = $(current).next('.banner').length!=0 ? $(current).next('.banner') : $('.banner:first-child');
  $(current).fadeOut(1500);
  $(next).fadeIn(1500);
}
/*
$('#edit-search').focus(function () {
	if($(this).val() === '') {
	  $('.compact-form-label').fadeOut('fast');
	}
});

$('#edit-search').blur(function () {
	if($(this).val() === '') {
	  $('.compact-form-label').fadeIn('slow');
	}
});*/

/*$(window).load(function() {
  $('#flexslider-1').flexslider("play");
});*/


  /**
   * Accordion tabs
   *
   * Extra functionality for the tabs used on the product page.
   */
  function updateAccordionTabs($element) {
    var $parent = $element.closest(".field-collection-view"),
      $accordion = $element.closest(".accordion-tabs");

    if ($parent.hasClass("active")) {
      $parent.removeClass("active").find(".field-name-field-body").slideUp("300");
      $accordion.attr("aria-expanded", "false");
    } else {
      /*
       * Tabs open laten op verzoek
       */
      $parent.addClass("active");
      $accordion.attr("aria-expanded", "true");
    }

    $(".field-collection-view.active .field-name-field-body").slideDown("300");
  }
  $(".accordion-tabs .field-name-field-title").attr('onclick', 'void(0)');
  $(".accordion-tabs .field-name-field-title").bind("click", function () {
    updateAccordionTabs($(this));
  });
  $(".accordion-tabs").keypress(function () {
    updateAccordionTabs($(this));
  });

  $(window).on('hashchange', function (e) {
    if (window.location.hash) {
      if ($('body.node-type-task, .node-task').length) {
        // Get the HASH
        var newhash = window.location.hash;
        $('.accordion-tabs ' + newhash + ' .field-name-field-title').click();
      //  if ($('.accordion-tabs li').css('float') == 'left') {
      //  }
      }
   }
  }).trigger('hashchange');

  $('.accordion-tabs').keypress(function() {
    this.blur();
    this.hideFocus = false;
    this.style.outline = null;
  });
  $('.accordion-tabs').mousedown(function() {
    this.blur();
    this.hideFocus = false;
    this.style.outline = 'none';
  });

  /***************** END accordion tabs ***************************************/
